import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'core/store';
import {
  IonButton,
  IonChip,
  IonContent,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow
} from '@ionic/react';
import {
  daysUntilRenewal,
  filterAppointments,
  formatSimpleDate,
  gaBtnClickEvent,
  gaToggle,
  getSeasonIcon
} from 'core/util/helpers';
import { fetchUpcomingVisits, fetchAppointmentList } from './DashboardActions';
import {
  hideAppointmentModal,
  resetAppointmentWizard,
  showAppointmentWizard
} from '../appointments/wizard/AppointmentWizardSlice';
import { registerForPushNotifications } from 'core/services/PushNotificatons';
import { removeDetailedAppointment } from '../appointments/AppointmentDetailsSlice';
import { loadFirebaseTokenFromStorage } from 'core/util';
import {
  CatalogStatus,
  DATE_FORMAT_MONTH_DATE,
  HKPlatform,
  HKScheduleType
} from 'core/constants';
import MenuHeader from 'core/layout/menu-header/MenuHeader';
import LoadingIonList from 'core/layout/LoadingIonList';
import AppointmentCard from 'components/cards/appointment/AppointmentCard';
import AppointmentWizard from '../appointments/wizard/AppointmentWizard';
import VisitCard from 'components/cards/visit/VisitCard';
import Calendar from 'assets/illustrations/calendar.svg';
import DownArrow from 'assets/icons/arrow-down.svg';
import './Dashboard.scss';
import { resetAppointmentList, resetVisitsList } from './DashboardSlice';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import HomeDigitalIcon from 'assets/icons/home-digital.svg';
import HomeSwitcher from 'components/home-switcher/HomeSwitcher';

const Dashboard: React.FC = () => {
  const { currentHome, globalAccountSettings, isExpired } = useSelector(
    (state: RootState) => state.home
  );
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { wizardModalVisible } = useSelector(
    (state: RootState) => state.appointmentWizard
  );
  const { upcomingVisits, appointments, loading } = useSelector(
    (state: RootState) => state.dashboard
  );
  const { name } = useSelector((state: RootState) => state.user);
  const { homes } = useSelector((state: RootState) => state.home);
  const [toggleIndex, setToggleIndex] = useState(0);
  const refresherRef = useRef<HTMLIonRefresherElement>(null);
  const toggleOptions = ['Up Next', 'History'];
  const dispatch = useDispatch();
  const history = useHistory();

  const refreshAppointmentList = () => {
    dispatch(resetAppointmentList());
    dispatch(resetVisitsList());
  };

  const viewAppointment = (id: number) => {
    if (id !== undefined) {
      dispatch(removeDetailedAppointment());
      history.push(`/appointment/${id}`);
    }
  };

  const redirectToInitialPage = () => {
    history.push(`/initial`);
  };

  const redirectToRenewPage = () => {
    history.push(`/renew`);
  };

  const redirectToRenew = () => {
    gaBtnClickEvent('account_renew');
    history.push(`/renew?required`);
  };

  const scheduleUpcoming = (
    homeId: number,
    hash: string,
    name: string,
    requiresRenewal: boolean
  ) => {
    gaBtnClickEvent('schedule_upcoming_appointment');
    if (requiresRenewal) {
      redirectToRenew();
    } else if (hash !== undefined) {
      dispatch(resetAppointmentWizard());
      dispatch(
        showAppointmentWizard({
          type: HKScheduleType.NEW,
          home_id: homeId,
          visit_hash: hash,
          name: name
        })
      );
    }
  };

  useEffect(() => {
    loadFirebaseTokenFromStorage().then((token) => {
      if (!token) {
        registerForPushNotifications();
      }
    });
  });

  useEffect(() => {
    if (!loading && !!refresherRef) {
      refresherRef.current?.complete();
    }
  }, [loading, refresherRef]);

  useEffect(() => {
    if (
      (currentHome?.catalog_status === undefined ||
        currentHome?.catalog_status === CatalogStatus.NOT_SCHEDULED) &&
      !isExpired &&
      currentHome?.is_diy === false
    ) {
      redirectToInitialPage();
    }

    if (!!currentHome && !appointments && currentHome?.is_diy === false) {
      dispatch(fetchAppointmentList(currentHome.id));
    }
  }, [currentHome, appointments]);

  useEffect(() => {
    if (!!currentHome && currentHome?.is_diy === false) {
      dispatch(fetchUpcomingVisits(currentHome.id));
      refreshAppointmentList();
    }
    gaToggle(globalAccountSettings?.impersonating === null);
  }, [currentHome]);

  function DesktopDashboardView() {
    return (
      <>
        {!!homes && homes.length > 1 && (
          <IonRow className="hk-desktop-dashboard-home-switcher">
            <div className="hk-desktop-dashboard-home-switcher-container">
              <div className="hk-desktop-digital-dashboard-home-switcher-title">
                HOME SWITCHER
              </div>
              <div className="hk-desktop-dashboard-home-switcher-content">
                <HomeSwitcher />
              </div>
            </div>
          </IonRow>
        )}
        <IonRow className="hk-desktop-dashboard-header">
          <div className="hk-desktop-dashboard-header-container">
            <div className="hk-desktop-dashboard-toggle">
              {toggleOptions.map((opt, i) => {
                return (
                  <div
                    key={`toggle-item-${i}`}
                    className={toggleIndex === i ? 'selected' : ''}
                    onClick={() => {
                      setToggleIndex(i);
                    }}
                  >
                    {opt}
                  </div>
                );
              })}
            </div>
            <div className="hk-desktop-dashboard-home-info">
              <div className="hk-desktop-dashboard-home-info-address">
                <img
                  src={HomeDigitalIcon}
                  className="hk-dashboard-home-info-address-icon"
                  alt={'Home'}
                />
                <span>{currentHome?.address1}</span>
              </div>
              <div className="hk-dashboard-home-info-welcome">
                Welcome Home <span>{name}</span>
              </div>
            </div>
          </div>
        </IonRow>
        <IonContent>
          {isExpired && (
            <div className="hk-desktop-dashboard-renew-banner-container">
              <div className="hk-desktop-dashboard-renew-banner">
                <div>
                  Your subscription has expired. In order to continue to
                  schedule service please renew.
                </div>
                <IonButton
                  expand="block"
                  strong={true}
                  className="renew-button"
                  onClick={() => redirectToRenewPage()}
                >
                  Renew Subscription
                </IonButton>
              </div>
            </div>
          )}
          <SwitchTransition>
            <CSSTransition
              key={toggleIndex ? 'Past Appointments' : 'Upcoming Appointments'}
              addEndListener={(node, done) =>
                node.addEventListener('transitionend', done, false)
              }
              classNames="toggle"
            >
              <div
                className={`hk-desktop-dashboard-appointments-animation ${
                  toggleIndex === 0 ? 'left' : 'right'
                }`}
              >
                <LoadingIonList
                  label={
                    toggleIndex ? 'Past Appointments' : 'Upcoming Appointments'
                  }
                  items={
                    toggleIndex
                      ? filterAppointments(toggleIndex, appointments || [])
                      : [
                          ...filterAppointments(
                            toggleIndex,
                            appointments || []
                          ),
                          ...(upcomingVisits || [])
                        ]
                  }
                  emptyImage={Calendar}
                  loading={loading}
                  className={'hk-desktop-dashboard-appointments'}
                >
                  {!!appointments &&
                    filterAppointments(toggleIndex, appointments).map(
                      (appt, i) => {
                        return (
                          <AppointmentCard
                            key={'appt' + i}
                            appointment={appt}
                            timezone={currentHome?.market_timezone || ''}
                            icon={getSeasonIcon(appt.name)}
                            onClick={() => viewAppointment(appt.id)}
                          />
                        );
                      }
                    )}
                  {toggleIndex === 0 &&
                    upcomingVisits?.map((visit, i) => {
                      return (
                        <VisitCard
                          key={'visit' + i}
                          icon={getSeasonIcon(
                            visit.name,
                            !visit.can_schedule || isExpired!
                          )}
                          visit={visit}
                          expired={isExpired}
                          onClick={() =>
                            scheduleUpcoming(
                              visit.home_id,
                              visit.plan_hash,
                              visit.name,
                              visit.requires_renewal
                            )
                          }
                        />
                      );
                    })}
                </LoadingIonList>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </IonContent>
      </>
    );
  }

  function MobileDashboardView() {
    return (
      <>
        <MenuHeader>
          <div className="hk-dashboard">
            <div className="hk-dashboard-home-info">
              <div className="hk-dashboard-home-info-address">
                <img
                  src={HomeDigitalIcon}
                  className="hk-dashboard-home-info-address-icon"
                  alt={'Home'}
                />
                <span>{currentHome?.address1}</span>
              </div>
              <div className="hk-dashboard-home-info-welcome">
                Welcome Home <span>{name}</span>
              </div>
            </div>
            <div className="hk-dashboard-toggle">
              {toggleOptions.map((opt, i) => {
                return (
                  <IonChip
                    key={'chip-' + i}
                    className={toggleIndex === i ? 'selected' : ''}
                    onClick={() => {
                      setToggleIndex(i);
                    }}
                  >
                    {opt}
                  </IonChip>
                );
              })}
            </div>
            {isExpired && (
              <div className="hk-dashboard-renew-banner-container">
                <div className="hk-dashboard-renew-banner">
                  <div>
                    Your subscription has expired. In order to continue to
                    schedule service please renew.
                  </div>
                  <IonButton
                    expand="block"
                    strong={true}
                    className="renew-button ion-margin-vertical"
                    onClick={() => redirectToRenewPage()}
                  >
                    Renew Subscription
                  </IonButton>
                </div>
              </div>
            )}
          </div>
        </MenuHeader>
        <IonContent className="ion-padding-vertical">
          <IonRefresher
            className="hk-refresher ion-padding"
            ref={refresherRef}
            slot="fixed"
            pullFactor={0.6}
            pullMin={100}
            onIonRefresh={refreshAppointmentList}
          >
            <IonRefresherContent
              className="ion-margin-top"
              pullingIcon={DownArrow}
              pullingText="Pull to refresh"
              refreshingText="Refreshing..."
            />
          </IonRefresher>
          <SwitchTransition>
            <CSSTransition
              key={toggleIndex ? 'Past Appointments' : 'Upcoming Appointments'}
              addEndListener={(node, done) =>
                node.addEventListener('transitionend', done, false)
              }
              classNames="toggle"
            >
              <div
                className={`hk-desktop-dashboard-appointments-animation ${
                  toggleIndex === 0 ? 'left' : 'right'
                }`}
              >
                <LoadingIonList
                  label={
                    toggleIndex ? 'Past Appointments' : 'Upcoming Appointments'
                  }
                  items={
                    toggleIndex
                      ? filterAppointments(toggleIndex, appointments || [])
                      : [
                          ...filterAppointments(
                            toggleIndex,
                            appointments || []
                          ),
                          ...(upcomingVisits || [])
                        ]
                  }
                  emptyImage={Calendar}
                  loading={loading}
                  className="ion-margin-vertical"
                >
                  {!!appointments &&
                    filterAppointments(toggleIndex, appointments).map(
                      (appt, i) => {
                        return (
                          <AppointmentCard
                            key={'appt' + i}
                            appointment={appt}
                            timezone={currentHome?.market_timezone || ''}
                            icon={getSeasonIcon(appt.name)}
                            onClick={() => viewAppointment(appt.id)}
                          />
                        );
                      }
                    )}
                  {toggleIndex === 0 &&
                    upcomingVisits?.map((visit, i) => {
                      return (
                        <VisitCard
                          key={'visit' + i}
                          icon={getSeasonIcon(
                            visit.name,
                            !visit.can_schedule || isExpired!
                          )}
                          visit={visit}
                          expired={isExpired}
                          onClick={() =>
                            scheduleUpcoming(
                              visit.home_id,
                              visit.plan_hash,
                              visit.name,
                              visit.requires_renewal
                            )
                          }
                        />
                      );
                    })}
                </LoadingIonList>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </IonContent>
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-dashboard-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDashboardView()
        : MobileDashboardView()}
      <IonModal
        className="hk-appointment-wizard-modal"
        isOpen={wizardModalVisible}
        onDidDismiss={() => {
          dispatch(hideAppointmentModal());
        }}
      >
        <AppointmentWizard />
      </IonModal>
    </IonPage>
  );
};

export default Dashboard;
