import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DocumentEdit.scss';
import {
  fetchBase64Document,
  gaBtnClickEvent,
  getDocumentFileTypeIcon,
  isFileTypeImage,
  isFileTypeVideo
} from 'core/util';
import {
  hideDesktopDocumentEditModal,
  hideDocumentEditActionsModal,
  showDocumentEditActionsModal
} from './DocumentsSlice';
import ArrowIcon from 'assets/icons/arrow-left.svg';
import renameIcon from 'assets/icons/rename.svg';
import shareIcon from 'assets/icons/share.svg';
import downloadIcon from 'assets/icons/download.svg';
import TrashIcon from 'assets/icons/trash.svg';
import { Share } from '@capacitor/share';
import { ellipsisHorizontal } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { updateDocument, deleteDocument } from './DocumentsActions';
import { displayError, displaySuccess } from 'core/services/AlertActions';
import { Filesystem, Directory } from '@capacitor/filesystem';
import Close from 'assets/icons/close.svg';
import ReactPlayer from 'react-player';

const DocumentEdit: React.FC = () => {
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);

  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { loading, selectedDocument, editActionsModalVisible } = useSelector(
    (state: RootState) => state.documents
  );
  const [desktopEditActionsVisible, setDesktopEditActionsVisible] =
    useState(false);
  const [desktopDeleteVisible, setDesktopDeleteVisible] = useState(false);
  const [renameInputVisible, setRenameInputVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');
  const [documentName, setDocumentName] = useState('');
  const documentNameInputRef = useRef<any>(null);

  const [deleteAlert] = useIonAlert();

  const dispatch = useDispatch();
  const history = useHistory();

  const documentOwnedByCurrentUser = () => {
    if (homeId && selectedDocument) {
      return homeId === selectedDocument.opt_home_id;
    } else {
      return false;
    }
  };

  const openEditModal = () => {
    gaBtnClickEvent('open_edit_document');
    dispatch(showDocumentEditActionsModal());
  };

  const closeEditModal = () => {
    gaBtnClickEvent('close_edit_document');
    dispatch(hideDocumentEditActionsModal());
    dispatch(hideDesktopDocumentEditModal());
    setDesktopEditActionsVisible(false);
    setDesktopDeleteVisible(false);
    setDeleteModalVisible(false);
    if (platformType !== HKPlatform.DESKTOP && !isDesktopWidth) {
      history.goBack();
    }
  };

  const showRenameDocumentField = () => {
    gaBtnClickEvent('rename_document');
    setRenameInputVisible(true);
    dispatch(hideDocumentEditActionsModal());
    setDesktopEditActionsVisible(false);
  };

  const updateDocumentName = () => {
    gaBtnClickEvent('rename_document');
    setDocumentName(documentNameInputRef.current.value);
    setRenameInputVisible(false);
    if (!!homeId && selectedDocument) {
      dispatch(
        updateDocument(homeId, Number(selectedDocument.id), {
          name: documentNameInputRef.current.value
        })
      )
        .then(() => {
          dispatch(displaySuccess('Name Updated', 'Success'));
          setRenameInputVisible(false);
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(displayError('Name Updated Failed'));
          setRenameInputVisible(false);
        });
    }
  };

  const downloadDocument = async () => {
    gaBtnClickEvent('download_document');
    const fileName: string = documentUrl.split('/').pop()!;
    const base64Data = await fetchBase64Document(documentUrl);
    if (
      platformType === HKPlatform.ANDROID ||
      platformType === HKPlatform.IOS ||
      platformType === HKPlatform.CAPACITOR
    ) {
      saveDocumentToMobile(fileName, base64Data);
    } else {
      saveDocumentToWeb(fileName, documentUrl);
      setDesktopEditActionsVisible(false);
    }
  };

  const saveDocumentToWeb = (fileName: string, url: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(displaySuccess('Document Saved', 'Success'));
        dispatch(hideDocumentEditActionsModal());
      })
      .catch((error) => console.error('Error downloading file:', error));
  };

  const saveDocumentToMobile = async (fileName: string, data: any) => {
    await Filesystem.writeFile({
      path: fileName,
      data: data,
      directory: Directory.Data,
      recursive: true
    })
      .then((result) => {
        dispatch(displaySuccess('Document Saved', 'Success'));
        dispatch(hideDocumentEditActionsModal());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeDocument = () => {
    gaBtnClickEvent('delete_document');
    if (!!homeId) {
      dispatch(deleteDocument(homeId, Number(selectedDocument?.id)))
        .then(() => {
          dispatch(displaySuccess('Document Deleted', 'Success'));
          closeEditModal();
        })
        .catch((error: any) => {
          dispatch(displayError('Document Delete Failed'));
          console.log(error);
        });
    }
  };

  const shareDocument = async () => {
    // Download document to temp cache then share if mobile (not available on desktop)
    gaBtnClickEvent('share_document');
    const fileName: string = documentUrl.split('/').pop()!;
    const base64Data = await fetchBase64Document(documentUrl);
    const { value } = await Share.canShare();
    if (value) {
      await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Cache,
        recursive: true
      });
      let fileResult = await Filesystem.getUri({
        directory: Directory.Cache,
        path: fileName
      });
      await Share.share({
        url: fileResult.uri
      })
        .then(() => {
          dispatch(displaySuccess('Document Shared', 'Success'));
          dispatch(hideDocumentEditActionsModal());
        })
        .catch((error) => console.log('Error sharing', error));
    } else {
      dispatch(displayError('File sharing not supported on desktop'));
    }
  };

  useEffect(() => {
    setDocumentUrl(selectedDocument?.file_url!);
    setDocumentName(selectedDocument?.name!);
  }, []);

  function MobileDeleteModalView() {
    return (
      <>
        <IonContent fullscreen className="hk-document-delete-account-content">
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button ${
                platformType === HKPlatform.DESKTOP && isDesktopWidth
                  ? ''
                  : 'hk-fab-button-margin-top'
              }`}
              onClick={() => setDeleteModalVisible(false)}
            >
              <IonIcon color="white" icon={Close} />
            </IonFabButton>
          </IonFab>
          <div className="hk-document-delete-account-body">
            <div>
              <h3 className="hk-document-delete-account-body-title">
                Delete Document
              </h3>
              <p className="hk-document-delete-account-body-description">
                Are you sure you want to delete your document?
              </p>
            </div>
            <div className="hk-document-delete-account-body-document">
              <IonIcon
                className="hk-document-delete-account-body-document-icon"
                color="white"
                icon={getDocumentFileTypeIcon(selectedDocument?.file_url!)}
              ></IonIcon>
              {documentName}
            </div>
          </div>
        </IonContent>
        <IonToolbar className="hk-document-delete-account-toolbar">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            disabled={loading}
            onClick={() => setDeleteModalVisible(false)}
          >
            Cancel
          </IonButton>
          <IonButton
            color="danger"
            className="hk-document-delete-account-button"
            expand="block"
            strong={true}
            onClick={removeDocument}
          >
            Confirm Delete
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  function DesktopDocumentsEditView() {
    return (
      <>
        <IonHeader>
          <IonToolbar className="hk-desktop-document-edit-toolbar">
            <>
              <IonTitle className="hk-desktop-document-edit-title">
                {documentName}
              </IonTitle>
              <IonButtons
                slot="end"
                className="hk-desktop-document-edit-button"
              >
                <IonButton
                  onClick={() =>
                    desktopEditActionsVisible
                      ? setDesktopEditActionsVisible(false)
                      : setDesktopEditActionsVisible(true)
                  }
                >
                  <IonIcon color="light" icon={ellipsisHorizontal} />
                </IonButton>
                <IonButton
                  onClick={() => dispatch(hideDesktopDocumentEditModal())}
                >
                  <IonIcon slot="end" color="white" icon={Close}></IonIcon>
                </IonButton>
              </IonButtons>
            </>
          </IonToolbar>
          {desktopEditActionsVisible && (
            <div className="hk-desktop-document-edit-dropdown-content">
              {documentOwnedByCurrentUser() && (
                <IonItem
                  lines="none"
                  className="hk-desktop-edit-document-modal-action-item"
                  onClick={() => showRenameDocumentField()}
                >
                  <IonIcon icon={renameIcon} slot="start" />
                  Rename Document
                </IonItem>
              )}

              <IonItem
                lines="none"
                className="hk-desktop-edit-document-modal-action-item"
                onClick={() => downloadDocument()}
              >
                <IonIcon icon={downloadIcon} slot="start" />
                Download Document
              </IonItem>
              {documentOwnedByCurrentUser() && (
                <section id="hk-desktop-edit-document-delete">
                  <div
                    className="hk-desktop-edit-document-delete-container"
                    onClick={() => {
                      setDesktopEditActionsVisible(false);
                      setDesktopDeleteVisible(true);
                    }}
                  >
                    <div className="hk-desktop-edit-document-delete-icon">
                      <IonIcon
                        slot="start"
                        className="hk-desktop-edit-document-edit-icon"
                        icon={TrashIcon}
                      />
                    </div>
                    <div className="hk-desktop-edit-document-delete-description">
                      <div className="hk-desktop-edit-document-delete-description-title">
                        Delete Document
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          )}
        </IonHeader>
        <IonContent className="hk-desktop-document-edit-body-container">
          <div className="hk-desktop-document-edit-body">
            {isFileTypeImage(selectedDocument?.file_url!) && (
              <>
                <div>
                  {documentUrl ? (
                    <img
                      src={selectedDocument?.optimized_url ?? documentUrl}
                      alt="documentImage"
                    />
                  ) : (
                    <div className="hk-desktop-document-edit-body-loading"></div>
                  )}
                </div>
              </>
            )}
            {isFileTypeVideo(selectedDocument?.file_url!) && (
              <>
                <ReactPlayer
                  width="100%"
                  height="100%"
                  controls={true}
                  onError={(e) => console.log(e)}
                  url={documentUrl}
                />
              </>
            )}
            {!isFileTypeImage(selectedDocument?.file_url!) &&
              !isFileTypeVideo(selectedDocument?.file_url!) && (
                <iframe
                  src={documentUrl}
                  width="100%"
                  height="100%"
                  title="digital-document"
                ></iframe>
              )}
          </div>
        </IonContent>
        {renameInputVisible && (
          <div className="hk-desktop-rename-footer">
            <IonInput
              ref={documentNameInputRef}
              className="hk-desktop-rename-input"
              value={documentName}
            ></IonInput>
            <IonButton
              className="hk-desktop-rename-button"
              onClick={() => updateDocumentName()}
            >
              Update Name
            </IonButton>
          </div>
        )}
        {desktopDeleteVisible && (
          <div className="hk-desktop-document-delete-overlay">
            <div className="hk-desktop-document-delete-overlay-body">
              <h3 className="hk-desktop-document-delete-overlay-body-title">
                Delete Document
              </h3>
              <p className="hk-desktop-document-delete-overlay-body-description">
                Are you sure you want to delete your document?
              </p>
              <div className="hk-desktop-document-delete-overlay-body-document">
                <IonIcon
                  className="hk-desktop-document-delete-overlay-body-document-icon"
                  color="white"
                  icon={getDocumentFileTypeIcon(selectedDocument?.file_url!)}
                ></IonIcon>
                {documentName}
              </div>
            </div>
            <div className="hk-desktop-document-delete-overlay-footer">
              <IonButton
                expand="block"
                color="white"
                strong={true}
                disabled={loading}
                onClick={() => {
                  setDesktopDeleteVisible(false);
                }}
              >
                Cancel
              </IonButton>
              <IonButton
                color="danger"
                expand="block"
                strong={true}
                onClick={removeDocument}
              >
                Confirm Delete
              </IonButton>
            </div>
          </div>
        )}
      </>
    );
  }

  function MobileDocumentsEditView() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="dark">
            {renameInputVisible ? (
              <>
                <IonButtons slot="start">
                  <IonButton
                    className="hk-rename-action"
                    onClick={() => setRenameInputVisible(false)}
                  >
                    Cancel
                  </IonButton>
                </IonButtons>
                <IonTitle className="hk-rename-title">Rename</IonTitle>
                <IonButtons slot="end">
                  <IonButton
                    className="hk-rename-action"
                    onClick={() => updateDocumentName()}
                  >
                    Done
                  </IonButton>
                </IonButtons>
              </>
            ) : (
              <>
                <IonButtons slot="start">
                  <IonButton onClick={() => closeEditModal()}>
                    <IonIcon color="light" icon={ArrowIcon} />
                  </IonButton>
                </IonButtons>
                <IonTitle className="hk-document-edit-title">
                  {documentName}
                </IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => openEditModal()}>
                    <IonIcon
                      className="hk-document-edit-button"
                      color="light"
                      icon={ellipsisHorizontal}
                    />
                  </IonButton>
                </IonButtons>
              </>
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="hk-document-edit-body">
            {isFileTypeImage(selectedDocument?.file_url!) && (
              <>
                <div>
                  <img
                    src={selectedDocument?.optimized_url ?? documentUrl}
                    alt="documentImage"
                  />
                </div>
              </>
            )}
            {isFileTypeVideo(selectedDocument?.file_url!) && (
              <>
                <ReactPlayer
                  width="100%"
                  height="100%"
                  controls={true}
                  onError={(e) => console.log(e)}
                  url={documentUrl}
                />
              </>
            )}
            {!isFileTypeImage(selectedDocument?.file_url!) &&
              !isFileTypeVideo(selectedDocument?.file_url!) && (
                <>
                  {platformType === HKPlatform.ANDROID ||
                  platformType === HKPlatform.IOS ||
                  platformType === HKPlatform.CAPACITOR ? (
                    <iframe
                      src={`https://docs.google.com/gview?url=${documentUrl}&embedded=true`}
                      width="100%"
                      height="100%"
                      title="digital-document"
                    ></iframe>
                  ) : (
                    <iframe
                      src={documentUrl}
                      width="100%"
                      height="100%"
                      title="digital-document"
                    ></iframe>
                  )}
                </>
              )}
          </div>
          {renameInputVisible && (
            <div className="hk-rename-footer">
              <IonInput
                ref={documentNameInputRef}
                className="hk-rename-input"
                value={documentName}
              ></IonInput>
            </div>
          )}
        </IonContent>
      </>
    );
  }

  return (
    <IonPage>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDocumentsEditView()
        : MobileDocumentsEditView()}
      <IonModal
        initialBreakpoint={
          platformType === HKPlatform.DESKTOP ? undefined : 0.5
        }
        breakpoints={
          platformType === HKPlatform.DESKTOP ? undefined : [0, 0.5, 0.75, 1]
        }
        className="hk-edit-document-modal"
        isOpen={editActionsModalVisible}
        onDidDismiss={() => {
          dispatch(hideDocumentEditActionsModal());
        }}
      >
        <div className="hk-edit-document-modal-body">
          {documentOwnedByCurrentUser() && (
            <IonItem
              lines="none"
              color={'dark'}
              className="hk-edit-document-modal-action-item"
              onClick={() => showRenameDocumentField()}
            >
              <IonIcon icon={renameIcon} slot="start" />
              Rename Document
            </IonItem>
          )}

          <IonItem
            lines="none"
            color={'dark'}
            className="hk-edit-document-modal-action-item"
            onClick={() => shareDocument()}
          >
            <IonIcon icon={shareIcon} slot="start" />
            Share Document
          </IonItem>
          <IonItem
            lines="none"
            color={'dark'}
            className="hk-edit-document-modal-action-item"
            onClick={() => downloadDocument()}
          >
            <IonIcon icon={downloadIcon} slot="start" />
            Download Document
          </IonItem>
          {documentOwnedByCurrentUser() && (
            <section id="hk-edit-document-delete">
              <div
                className="hk-edit-document-delete-container"
                onClick={() => setDeleteModalVisible(true)}
              >
                <div className="hk-edit-document-delete-icon">
                  <IonIcon
                    slot="start"
                    className="hk-edit-document-edit-icon"
                    icon={TrashIcon}
                  />
                </div>
                <div className="hk-edit-document-delete-description">
                  <div className="hk-edit-document-delete-description-title">
                    Delete Document
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </IonModal>
      <IonModal
        className="hk-digital-documents-delete-modal"
        isOpen={deleteModalVisible}
        onDidDismiss={() => {
          setDeleteModalVisible(false);
        }}
      >
        <MobileDeleteModalView />
      </IonModal>
    </IonPage>
  );
};

export default DocumentEdit;
