interface IObjectKeys {
  [key: string]:
    | string
    | number
    | boolean
    | object
    | object[]
    | undefined
    | null;
}

export interface Heartbeat {
  debug: boolean;
  env: string;
  homekeep_version: string;
  python_version: string;
  query_data: object;
}

export interface ContactInfo {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  category?: string | null;
  email?: string | null;
  home_ids?: number[];
  home_phone?: string | null;
  id?: number;
  image?: string | null;
  image_id?: string | null;
  label?: string | null;
  mobile_phone?: string | null;
  name?: string;
  postal_code?: string | null;
  state?: string | null;
  type?: string;
  type_id?: number;
  url?: string | null;
  work_phone?: string | null;
}

export interface AppointmentProposalGroup {
  date: string;
  proposals: AppointmentProposal[];
}

export interface AppointmentProposal {
  duration: number;
  schedule_hash: string;
  schedule_start: string;
  schedule_end: string;
  time: string;
  day: string;
  date: string;
}

export interface Appointment extends IObjectKeys {
  allow_cancel: boolean;
  allow_reschedule: boolean;
  assets: Asset[];
  created: string;
  description: string;
  duration: number;
  google_event_id: number;
  home_id: number;
  id: number;
  image?: string;
  finished?: string;
  name: string;
  homeowner_notes?: string;
  scheduled: string;
  worker?: Worker;
  worker_notes?: string;
  type?: string;
  type_id?: number;
  // UI convenience properties
  display_date: string;
  display_time: string;
}

export interface Worker {
  id?: number;
  type?: string;
  type_id?: number;
  name?: string;
  title?: string;
  image?: string;
  rating?: number;
  google_calendar_id?: number;
}

export interface Task extends IObjectKeys {
  assets: Asset[];
  finished: string;
  id: number;
  image?: string;
  name: string;
  worker_notes?: string;
  statuses?: StatusTag[];
  tag_ids?: string;
  type?: string;
  type_id?: number;
}

export interface TaskBodyDetails {
  finished?: Date;
  finished_by_id?: number | null;
  tag_ids?: number[];
  notes?: any;
}

export interface StatusTag {
  digital: boolean;
  id: number;
  level: number;
  name: string;
}

export interface Document {
  id: number;
  name: string;
  image: string;
  file_url: string;
  type: string;
  thumbnail_url: string | null;
  type_id: number;
  product_data: {
    manufacturer: number;
    manufacturer_name: string;
    manufacturer_image: string;
    model: string;
    name: string;
    scope: string;
    serial: string;
    type_name: string;
  };
}

export interface Attribute {
  name: string;
  value: string;
}

export interface InventoryItem {
  home_id: number;
  assets: Asset[];
  description: string;
  name: string;
  id: number;
  image: string;
  model: string;
  registered: string;
  notes: string;
  product_template: ProductTemplate;
  documents: Document[];
  serial: string;
  task_groups: object[];
  type: string;
  type_id: number;
}

export interface AttributeSchema {
  allow?: any;
  default: string;
  field: string;
  label: string;
  type: string;
}

export interface ProductTemplate {
  assets: Asset[];
  created: string;
  description: string;
  id: number;
  image: string;
  image_id: number;
  manufacturer: Manufacturer;
  manufacturer_image: string;
  manufacturer_name: string;
  model: string;
  name: string;
  rooms: Room[];
  type: string;
  type_data?: any;
  type_id: number;
}

export interface ProductTemplateType {
  attribute_schema: AttributeSchema[];
  field: string;
  id: number;
  label: string;
  product_attribute_schema: AttributeSchema[];
  type_group_name: string;
}

export interface ProductBodyDetails {
  home_id?: number;
  type?: string;
  model_photo?: string;
  brand_photo?: string;
  attributes?: any;
}

export interface ProductGroupType {
  id: number;
  name: string;
  product_template_types: ProductTemplateType[];
}

export interface SearchedProduct {
  group_id: number;
  group_name: string;
  id: number;
  image: string;
  image_id: number;
  name: string;
  product_template: ProductTemplate;
  registered: string;
  serial: string;
  type: string;
  type_id: number;
  typed: number;
}

export interface Manufacturer {
  id: number;
  image: string;
  name: string;
  type: string;
  type_id: number;
}

export interface Room {
  id: number;
  image: string;
  name: string;
  type: string;
  type_id: number;
}

export interface InventoryGroup {
  id: number;
  items: InventoryItem[];
  label: string;
}

export interface Asset extends IObjectKeys {
  file_url: string;
  id: number;
  name: string;
  type: string;
  type_id: number;
  cloud_store: string | null;
  default: boolean;
  file_name: string | null;
  opt_home_id: number | null;
  thumb_cloud_store: string | null;
}

export interface AssetGroup {
  id: number;
  items: Asset[];
  label: string;
}

export enum HKSignupStep {
  Zip = 1,
  Email,
  Size,
  Packages,
  Done
}

export enum HKHouseSize {
  Small = 1,
  Medium,
  Large
}

export enum HKMarketTier {
  High = 1,
  Medium,
  Low
}

export enum HKPackageType {
  Essential = 'es',
  Preferred = 'pr'
}

export interface HKPackage {
  type: HKPackageType;
  name: string;
  price: string;
  term: string;
  code: string;
}

export interface HKSubscription {
  code: string;
  name: string;
}

export interface DigitalSignup {
  access_token: string;
  home_id: number;
  user_id: number;
}

export interface DigitalTask {
  duration: number | null;
  finished: boolean | null;
  id: number;
  image: string | null;
  image_id: number | null;
  name: string;
  notes: string | null;
  product_type_id: number | null;
  product_type_label: string | null;
  product_type_name: string | null;
  scheduled: string;
  tutorial_url: string | null;
  type: string;
  type_id: number;
}

export interface DigitalTaskDetails {
  consumables: DigitalConsumable[];
  diy_instructions: string;
  duration: number;
  finished: string | null;
  finished_by: string | null;
  id: number;
  image: string | null;
  image_id: number | null;
  name: string;
  notes: string | null;
  product: DigitalProduct | null;
  product_type_id: number | null;
  product_type_name: string;
  scheduled: string;
  sequence: number;
  status_tags: StatusTag[];
  steps: any[];
  tools: DigitalConsumable[];
  tutorial_url: string | null;
  type: string;
  type_id: number;
  why: string;
}

export interface DigitalConsumable {
  link: string | null;
  name: string;
}

export interface DigitalProduct {
  id: number;
  image: string | null;
  image_id: number | null;
  is_generic: boolean;
  name: string;
  registered: string;
  type: string;
  type_id: number;
}

export interface DigitalProductTypeTogglesList {
  toggles: DigitalProductTypeToggle[];
}

export interface DigitalProductTypeToggle {
  current_state: boolean;
  group_id: number;
  group_name: string;
  id: number;
  label: string;
}

export interface DigitalCatalogTask {
  description: string;
  duration: number | null;
  finished: string | null;
  id: number;
  image: string | null;
  image_id: number | null;
  name: string;
  product_type_group: string;
  product_type_group_id: number;
  product_type_id: number;
  product_type_name: string;
  scheduled: string;
  tutorial_url: string | null;
  type: string;
  type_id: number;
}

export interface DocumentCount {
  count: number;
  type: string;
  group: string;
  label: string;
}

export interface DocumentType {
  attribute_schema: any[];
  field: string;
  id: number;
  label: string;
}

export interface DocumentBody {
  name: string;
  type: string | null;
  file_url: string;
}

export interface SearchedDocument {
  file_url: string;
  id: number;
  image_id: number | null;
  name: string;
  opt_home_id: number;
  optimized_url: string | null;
  thumbnail_url: string | null;
  type: string;
  type_id: number;
  type_label: string;
}

export interface BuilderLogo {
  name: string;
  logo_url: string;
}
